import { clsx } from 'clsx';
import { useRef, forwardRef } from 'react';
import { useTab } from 'react-aria';
import { Tooltip } from '@ping/uikit/TooltipA11Y';

import style from './style.module.scss';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface ITabProps<T> {
  item: IReactStately.SingleSelectListState<T>['selectedItem'];
  state: IReactStately.TabListState<T>;
  orientation?: IReactAria.AriaTabListProps<T>['orientation'];
  variant: ITabsVariant;
  disabled?: boolean;
  disableMessage?: string;
}

const TabItemView = forwardRef<
  HTMLDivElement,
  { item: ITabProps<any>['item']; variant: ITabsVariant; disabled?: boolean; tabProps: any }
>(({ item, variant, disabled, tabProps }, ref) => (
  <div
    className={clsx(style['tab'], item.props.className)}
    data-variant={variant}
    aria-disabled={disabled}
    {...tabProps}
    ref={ref}
  >
    {item.rendered}
  </div>
));

export const Tab = <T,>({ item, variant, state, disabled, disableMessage }: ITabProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { tabProps } = useTab(item, state, ref);

  if (disabled && disableMessage) {
    return (
      <Tooltip.Root>
        <Tooltip.Trigger className={style['tooltip--trigger']}>
          <TabItemView ref={ref} item={item} variant={variant} disabled={disabled} tabProps={tabProps} />
        </Tooltip.Trigger>
        <Tooltip.Content className={style['tooltip--content']}>{disableMessage}</Tooltip.Content>
      </Tooltip.Root>
    );
  }

  return <TabItemView ref={ref} item={item} variant={variant} disabled={disabled} tabProps={tabProps} />;
};
