import { useRef } from 'react';
import { useTabPanel } from 'react-aria';

import style from './style.module.scss';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface ITabPanelProps<T> extends IReactAria.AriaTabPanelProps {
  panelKey: IReactStately.SingleSelectListState<T>['selectedKey'];
  state: IReactStately.TabListState<T>;
  children: React.ReactNode;
}

export const TabPanel = <T,>(props: ITabPanelProps<T>) => {
  const ref = useRef<HTMLDivElement>();
  const { tabPanelProps } = useTabPanel(props, props.state, ref);
  const isSelected = props.state.selectedItem?.key === props.panelKey;

  return (
    <div
      className={style['tab-panel']}
      aria-selected={isSelected}
      hidden={!isSelected}
      {...(isSelected ? tabPanelProps : {})}
      ref={ref}
    >
      {props.children}
    </div>
  );
};
